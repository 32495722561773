footer.black {
	margin-bottom: 0;
	padding-top: 2em;
	padding-bottom: 2em;
/* 	color: white; */
	text-align: left;
}

.black {
	background-color: #000;
	color: #fff;
	padding: 2em 10%;
}

.grid-1-1-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
}

footer a:link, footer a:active, footer a:visited, footer a:focus {
  color: white;
}

footer a {
	display: block;
	line-height: 2em;
}

footer p, footer a {
	font-size: 0.8em;
}
.copyrights {
	margin-top: 4em;
}
footer.black {
	margin-bottom: 0;
	padding-top: 2em;
	padding-bottom: 2em;
}

@media all and (max-width: 950px){
	div.grid-1-1-1-1.footerNav {
		grid-template-columns: 1fr 1fr;
	}
}

@media all and (max-width: 650px){
	div.grid-1-1-1-1.footerNav {
		grid-template-columns: 1fr;
	}
}