@font-face {
  font-family:  'Bison';
  src: url('./fonts/Bison-Bold.ttf') format('truetype');
}

body {
  background-color: whitesmoke;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-weight: 300;
  font-style: normal;
}

h1,h2,h3,h4,h5 {
  font-family: 'Bison',-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

main {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

}
.student {
  /* font-family: 'Anonymous Pro', monospace; */
  box-sizing: border-box;
  padding: 2em 0;
  text-align: left;
  margin: 1em 1em 1em 0;
  flex: 1 1 250px;
  padding: .5em;
  box-shadow: -3px 3px #000!important;
  -webkit-transform: translate(3px,-3px);
  transform: translate(3px,-3px);
  outline: none;

}


.student h1 {
  height: 2em;
}

.student p, .student div {
  /* flex:  1; */
  word-break: break-all;
}

.places {
  display: inline-block;
  margin-right: 1em;
}

.green {
  color:  green;
}
.red {
  color:  red;
}
.hide {
  display: none;
}
.crossedOut {
  color: red;
  text-decoration: line-through;
}

input {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-weight: 300;
  font-style: normal;
  padding:  .5em;
  font-size: inherit;
}


header h1 {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxwYXR0ZXJuIGlkPSJwYXR0ZXJuLWNoZWNrZXJzIiB4PSIwIiB5PSIwIiB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHBhdHRlcm5Vbml0cz0idXNlclNwYWNlT25Vc2UiPjxyZWN0IHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlLXdpZHRoOjE7c3Ryb2tlOnJnYigwLDAsMCkiIGNsYXNzPSJjaGVja2VyIiB4PSIwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgeT0iMCIvPjxyZWN0IHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlLXdpZHRoOjE7c3Ryb2tlOnJnYigwLDAsMCkiIGNsYXNzPSJjaGVja2VyIiB4PSIxMDAiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiB5PSIxMDAiLz48L3BhdHRlcm4+PHJlY3Qgc3R5bGU9InN0cm9rZS13aWR0aDoxO3N0cm9rZTpyZ2IoMCwwLDApIiB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI3BhdHRlcm4tY2hlY2tlcnMpIi8+PC9zdmc+);
  margin: 0;
  padding: 1.5em 0;
  width: 100%;
  text-align: left;
  font-size: 2em;
}

header h1 a:link {
  color: black;
}

header h1 {
  font-size: 3em;
}

.nav {
  background-color: black;
  color:  white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 10%;
  text-align: center;
}
.nav p {
  margin: 0;
  text-align: left;
}

.nav a:link, .nav a:active, .nav a:visited, .nav a:focus {
  color: white;
}


.sortDate {
  text-decoration: underline;
}

a:link, a:active, a:visited, a:focus {
  color: black;
}



@media screen and (max-width: 460px){
  header h1 {
    font-size: 2em;
  }
  main {
    width: 90%;
  }
  .nav {
    flex-direction: column;
  }
}